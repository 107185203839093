import React, { ReactNode } from "react"
import Header from "../constants/header"
import Footer from "../constants/footer"

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <div id="page-wrapper">
        <main>{children}</main>
      </div>

      <Footer></Footer>
    </>
  )
}

export default Layout
