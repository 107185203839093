import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import useHandleBurgerMenu from "./useHandleBurgerMenu"

interface RouterProps {
  route: string
  label: string
  id: number
  class: string
}

export default function useRoutes() {
  let burgerRoutes: any = []
  let desktopRoutes: any = []

  const { handleBurgerMenu } = useHandleBurgerMenu()
  const data = useStaticQuery(graphql`
    {
      allRoutesJson {
        nodes {
          label
          route
          id
        }
      }
    }
  `)

  const { allRoutesJson } = data

  // Desktop -- Loop threw the routes.json data and output a Link element for each route

  desktopRoutes = allRoutesJson.nodes.map(
    ({ label, route, id }: RouterProps) => {
      return (
        <Link
          key={id}
          to={route}
          getProps={({ isCurrent }) => {
            return {
              className: isCurrent ? "nav-link active" : "nav-link",
            }
          }}
        >
          {label}
        </Link>
      )
    }
  )

  // Todo: Why does the handleBurgerMenu func not close the burgermenu!??
  // The state updates but the menu doesnt close. But when using links directly
  // it works ..

  burgerRoutes = allRoutesJson.nodes.map(
    ({ label, route, id }: RouterProps) => (
      <Link
        key={id}
        className="nav-link"
        to={route}
        onClick={() => handleBurgerMenu()}
      >
        {label}
      </Link>
    )
  )

  return {
    burgerRoutes,
    desktopRoutes,
  }
}
