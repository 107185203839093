import { useState } from "react"

// Do we need an interface here?

export default function useHeaderTheme() {
  const [headerTheme, setHeaderTheme] = useState("light-theme")

  // Check what the current path is and set the appropriate theme for the header
  // items.

  const handleHeaderTheme = (currentPath: string) => {
    if (currentPath !== "/") {
      setHeaderTheme("dark-theme")
    } else if (currentPath === "/") {
      setHeaderTheme("light-theme")
    }
  }
  return {
    handleHeaderTheme,
    headerTheme,
  }
}
