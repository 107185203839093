import React from "react"

interface FooterProps {}

const footer = () => {
  return (
    <footer id="footer">
      <div className="footer-items">
        <span className="label">netfang</span>
        <a className="nav-link">rosa.olof@gmail.com</a>
        <span className="p-main">© {new Date().getFullYear()}</span>
      </div>
    </footer>
  )
}

export default footer
