import React, { useEffect } from "react"
import { IoIosClose } from "../../../node_modules/react-icons/io"
import { Link } from "gatsby"
import useHandleBurgerMenu from "../../hooks/useHandleBurgerMenu"
import useRoutes from "../../hooks/useRoutes"
import useHeaderTheme from "../../hooks/useHeaderTheme"

const Header: React.FC = () => {
  const { handleBurgerMenu, burgerMenuVisability } = useHandleBurgerMenu()
  const { burgerRoutes, desktopRoutes } = useRoutes()
  const { handleHeaderTheme, headerTheme } = useHeaderTheme()

  // Check if the window object is defined when the comp mounts and if so
  // call handleHeaderTheme and pass the current route to it.

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    handleHeaderTheme(location.pathname)
  })

  return (
    <>
      <div
        className={burgerMenuVisability ? "burger-menu" : "burger-menu-closed"}
      >
        <div id="btn-box">
          <Link to="/" className="logo" onClick={() => handleBurgerMenu()}>
            Rósa.Ólöf
          </Link>
          <IoIosClose
            className="close-btn"
            role="button"
            tabIndex={0}
            size={48}
            color="white"
            onClick={() => handleBurgerMenu()}
          ></IoIosClose>
        </div>
        {/* To do: figure out why the burgerMenuVisablity state doesnt update */}
        {/* <nav id="nav-links-burger">{burgerRoutes}</nav> */}
        <nav id="nav-links-burger">
          <Link className="nav-link" to="/" onClick={() => handleBurgerMenu()}>
            Heim
          </Link>
          <Link
            className="nav-link"
            to="/blogg"
            onClick={() => handleBurgerMenu()}
          >
            Blogg
          </Link>
          <Link
            className="nav-link"
            to="/baekur"
            onClick={() => handleBurgerMenu()}
          >
            Bækur
          </Link>
          {/* <Link
            className="nav-link"
            to="/um-mig"
            onClick={() => handleBurgerMenu()}
          >
            Um mig
          </Link> */}
        </nav>
      </div>
      <header id="header" className={headerTheme}>
        <div id="header-items-container">
          <div id="main-Logo">
            <Link to="/" className="logo">
              Rósa.Ólöf
            </Link>
          </div>
          <nav id="nav-links-desktop">{desktopRoutes}</nav>
          <button id="burger-btn" onClick={() => handleBurgerMenu()}>
            <div id="burger-item-long"></div>
            <div id="burger-item-short"></div>
          </button>
        </div>
      </header>
    </>
  )
}
export default Header
