import { useState } from "react"

export default function useHandleBurgerMenu() {
  const [burgerMenuVisability, setBurgerMenuVisability] = useState(false)

  const handleBurgerMenu = () => {
    setBurgerMenuVisability(!burgerMenuVisability)
    const htmlElement = document.querySelector("html") as HTMLElement
    htmlElement.classList.toggle("overflow-off")
  }

  return {
    handleBurgerMenu,
    burgerMenuVisability,
    setBurgerMenuVisability,
  }
}
