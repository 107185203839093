// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-baekur-tsx": () => import("./../../../src/pages/baekur.tsx" /* webpackChunkName: "component---src-pages-baekur-tsx" */),
  "component---src-pages-blogg-tsx": () => import("./../../../src/pages/blogg.tsx" /* webpackChunkName: "component---src-pages-blogg-tsx" */),
  "component---src-pages-home-home-tsx": () => import("./../../../src/pages/home/home.tsx" /* webpackChunkName: "component---src-pages-home-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-um-mig-tsx": () => import("./../../../src/pages/um-mig.tsx" /* webpackChunkName: "component---src-pages-um-mig-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/WpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */)
}

